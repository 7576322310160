import React from "react"
import Layout from "../components/layout"

export default function Contact() {
  return (
    <Layout>
      <p>Contact.</p>
      <img src="https://source.unsplash.com/random/400x200" alt="" />
      </Layout>
  )
}